import { Alert, BlockDash, Container } from '@elotech/components';
import { History } from 'history';
import { Modulos } from 'iss-common/enums';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Parametros from '../../service/Parametros';
import ProcessoService from '../../service/ProcessoService';
import SolicitacaoBaixaRedeSimService from '../../service/SolicitacaoBaixaRedeSimService';
import UsuarioService from '../../service/UsuarioService';

type Props = {
  history: Pick<History, 'push'>;
  usuario: any;
};

const Home: React.FC<Props> = ({ usuario, history }) => {
  const [
    processosComAtosComPrazoEncerrado,
    setProcessosComAtosComPrazoEncerrado
  ] = useState<number>(0);
  const [
    loadingProcessosComAtosComPrazoEncerrado,
    setLoadingProcessosComAtosComPrazoEncerrado
  ] = useState<boolean>(false);

  const [processosAbertos, setProcessosAbertos] = useState<number>(0);
  const [loadingProcessosAbertos, setLoadingProcessosAbertos] = useState<
    boolean
  >(false);

  const [totalSolicitacoesBaixa, setTotalSolicitacoesBaixa] = useState<number>(
    0
  );
  const [loadingSolicitacoesBaixa, setLoadingSolicitacoesBaixa] = useState<
    boolean
  >(false);

  const [meusProcessosAbertos, setMeusProcessosAbertos] = useState<number>(0);
  const [
    loadingMeusProcessosAbertos,
    setLoadingMeusProcessosAbertos
  ] = useState<boolean>(false);

  const [
    processosAguardandoMeuAceite,
    setProcessosAguardandoMeuAceite
  ] = useState<number>(0);
  const [
    loadingProcessosAguardandoMeuAceite,
    setLoadingProcessosAguardandoMeuAceite
  ] = useState<boolean>(false);

  const [habilitaSolicitacaoBaixa, setHabilitaSolicitacaoBaixa] = useState<
    boolean
  >(false);

  const carregarParametroHabilitaSolicitacaoBaixa = useCallback(() => {
    Parametros.loadParametros(Modulos.ISS, 'HABILITASOLICITACAOBAIXA')
      .then(response => setHabilitaSolicitacaoBaixa(response.data === 'S'))
      .catch(error => {
        Alert.error(
          { title: 'Erro ao carregar parâmetro HABILITASOLICITACAOBAIXA.' },
          error
        );
      });
  }, []);

  const carregarQuantidadeProcessosComAtosComPrazoEncerrado = useCallback(() => {
    setLoadingProcessosComAtosComPrazoEncerrado(true);
    ProcessoService.quantidadeProcessosComAtosComPrazoEncerrado()
      .then(response => {
        setProcessosComAtosComPrazoEncerrado(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title:
              'Não foi possível obter a quantidade de processos com atos com prazo encerrado.'
          },
          error
        );
      })
      .finally(() => setLoadingProcessosComAtosComPrazoEncerrado(false));
  }, []);

  const carregarQuantidadeProcessosAbertos = useCallback(() => {
    setLoadingProcessosAbertos(true);
    ProcessoService.quantidadeProcessosAbertos()
      .then(response => {
        setProcessosAbertos(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Não foi possível obter a quantidade de processos abertos.'
          },
          error
        );
      })
      .finally(() => setLoadingProcessosAbertos(false));
  }, []);
  const carregarQuantidadeSolicitacoesBaixaRedeSim = useCallback(() => {
    setLoadingSolicitacoesBaixa(true);
    SolicitacaoBaixaRedeSimService.findTotalSolicitacoes()
      .then(response => {
        setTotalSolicitacoesBaixa(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title:
              'Não foi possível obter a quantidade de solicitações de baixa.'
          },
          error
        );
      })
      .finally(() => setLoadingSolicitacoesBaixa(false));
  }, []);

  const carregarQuantidadeMeusProcessosAbertos = useCallback((usuario: any) => {
    setLoadingMeusProcessosAbertos(true);
    ProcessoService.quantidadeMeusProcessosAbertos(usuario.profile.username)
      .then(response => {
        setMeusProcessosAbertos(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Não foi possível obter a quantidade de processos abertos.'
          },
          error
        );
      })
      .finally(() => setLoadingMeusProcessosAbertos(false));
  }, []);

  const carregarQuantidadeProcessosAguardandoMeuAceite = useCallback(
    (usuario: any) => {
      setLoadingProcessosAguardandoMeuAceite(true);
      ProcessoService.quantidadeProcessosAguardandoMeuAceite(
        usuario.profile.username
      )
        .then(response => {
          setProcessosAguardandoMeuAceite(response.data);
        })
        .catch(error => {
          Alert.error(
            {
              title: 'Não foi possível obter a quantidade de processos abertos.'
            },
            error
          );
        })
        .finally(() => setLoadingProcessosAguardandoMeuAceite(false));
    },
    []
  );

  const onProcessosAbertos = () =>
    history.push('/processo/?filters==status_Igual=ABERTO');

  const onMeusProcessosAbertos = () => {
    UsuarioService.findUsuarioByCpf(usuario.profile.username)
      .then(response => {
        history.push(
          `/processo/?filters==status_Igual=ABERTO,fiscalResponsavel_Igual=${response.data}`
        );
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Não foi possível obter realizar a consulta.'
          },
          error
        );
      });
  };

  const onMeusProcessosAguardandoAceite = () => {
    UsuarioService.findUsuarioByCpf(usuario.profile.username)
      .then(response => {
        history.push(
          `/processo/?filters==status_Igual=EM_TRANSFERENCIA,fiscalResponsavel_Igual=${response.data}`
        );
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Não foi possível obter realizar a consulta.'
          },
          error
        );
      });
  };

  const onSolicitacoesBaixaRedeSim = () =>
    history.push('/solicitacao-baixa-redesim');

  const onProcessosComAtosComPrazoEncerrado = () => {
    history.push(`/processo/?processosComAtosComPrazoEncerrado`);
  };

  useEffect(() => {
    carregarParametroHabilitaSolicitacaoBaixa();
    carregarQuantidadeProcessosComAtosComPrazoEncerrado();
    carregarQuantidadeProcessosAbertos();
    carregarQuantidadeMeusProcessosAbertos(usuario);
    carregarQuantidadeProcessosAguardandoMeuAceite(usuario);
    if (habilitaSolicitacaoBaixa) {
      carregarQuantidadeSolicitacoesBaixaRedeSim();
    }
  }, [
    usuario,
    habilitaSolicitacaoBaixa,
    carregarQuantidadeProcessosComAtosComPrazoEncerrado,
    carregarQuantidadeProcessosAbertos,
    carregarQuantidadeMeusProcessosAbertos,
    carregarQuantidadeProcessosAguardandoMeuAceite,
    carregarQuantidadeSolicitacoesBaixaRedeSim,
    carregarParametroHabilitaSolicitacaoBaixa
  ]);

  return (
    <Container title="Início" icon="home">
      <BlockDash
        md={6}
        icon="fa fa-medal"
        title="Processos com atos vencidos"
        number={processosComAtosComPrazoEncerrado || '0'}
        loading={loadingProcessosComAtosComPrazoEncerrado}
        onClick={onProcessosComAtosComPrazoEncerrado}
      />

      <BlockDash
        md={6}
        icon="fa fa-medal"
        title="Processos em aberto"
        number={processosAbertos || '0'}
        loading={loadingProcessosAbertos}
        onClick={onProcessosAbertos}
      />

      <BlockDash
        md={6}
        icon="fa fa-medal"
        title="Meus Processos abertos"
        number={meusProcessosAbertos || '0'}
        loading={loadingMeusProcessosAbertos}
        onClick={onMeusProcessosAbertos}
      />

      <BlockDash
        md={6}
        icon="fa fa-medal"
        title="Processos aguardando meu aceite"
        number={processosAguardandoMeuAceite || '0'}
        loading={loadingProcessosAguardandoMeuAceite}
        onClick={onMeusProcessosAguardandoAceite}
      />

      {habilitaSolicitacaoBaixa && (
        <BlockDash
          md={6}
          icon="fa fa-medal"
          title="Solicitações de Baixa - Redesim"
          number={totalSolicitacoesBaixa || '0'}
          loading={loadingSolicitacoesBaixa}
          onClick={onSolicitacoesBaixaRedeSim}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuario: state.user
});
const connectedComponent = connect(mapStateToProps)(Home);
export { connectedComponent as default, Home };
