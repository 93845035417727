import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  FAB,
  FabSpeedDial,
  Field,
  FormattedDate,
  Loading,
  PageRequest,
  Panel,
  SearchFilter,
  SearchPagination,
  Sort,
  Table,
  usePagedQuery
} from '@elotech/components';
import download from 'downloadjs';
import { History } from 'history';
import React, { useCallback, useState } from 'react';

import { StatusRender } from '../../common/components/StatusRender';
import ProcessoService from '../../service/ProcessoService';
import { Processo } from '../../types/Processo';

type Props = {
  history: Pick<History, 'push'>;
  location: Pick<Location, 'search'>;
};

const fields: Field[] = [
  {
    label: 'Número Processo',
    name: 'numero',
    type: 'NUMBER'
  },
  {
    label: 'Número Protocolo',
    name: 'numeroProtocolo',
    type: 'STRING'
  },
  {
    label: 'Situação',
    name: 'status',
    type: 'ENUM',
    options: [
      {
        name: 'ABERTO',
        descricao: 'Aberto'
      },
      {
        name: 'CANCELADO',
        descricao: 'Cancelado'
      },
      {
        name: 'EM_TRANSFERENCIA',
        descricao: 'Em transferência'
      },
      {
        name: 'FECHADO',
        descricao: 'Fechado'
      }
    ]
  },
  {
    label: 'Exercício',
    name: 'exercicio',
    type: 'NUMBER'
  },
  {
    label: 'Data Abertura',
    name: 'dataAbertura',
    type: 'DATE'
  },
  {
    label: 'Tipo do Processo',
    name: 'tipoProcesso.descricao',
    type: 'STRING'
  },
  {
    label: 'CNPJ/Razão Social',
    name: 'REUFISCALIZACAO',
    type: 'STRING'
  },
  {
    label: 'CPF Fiscal Responsável',
    name: 'fiscalResponsavelAtual.pessoa.cnpjCpf',
    type: 'STRING'
  },
  {
    label: 'Nome Fiscal Responsável',
    name: 'fiscalResponsavelAtual.pessoa.nome',
    type: 'STRING'
  },
  {
    label: 'CPF/CNPJ Denunciante',
    name: 'denunciante.cnpjCpf',
    type: 'STRING'
  },
  {
    label: 'Nome Denunciante',
    name: 'denunciante.nome',
    type: 'STRING'
  },
  {
    label: 'Inscrição Municipal',
    name: 'locais.cadastroGeral.cadastroGeral',
    type: 'NUMBER'
  }
];

const fieldsProcessosComAtosComPrazosEncerrados: Field[] = [
  {
    label: 'Número Processo',
    name: 'numero',
    type: 'NUMBER'
  },
  {
    label: 'Exercício',
    name: 'exercicio',
    type: 'NUMBER'
  },
  {
    label: 'Tipo do Processo',
    name: 'tipoProcesso.descricao',
    type: 'STRING'
  },
  {
    label: 'CPF Fiscal Responsável',
    name: 'fiscalResponsavelAtual.pessoa.cnpjCpf',
    type: 'STRING'
  },
  {
    label: 'Nome Fiscal Responsável',
    name: 'fiscalResponsavelAtual.pessoa.nome',
    type: 'STRING'
  },
  {
    label: 'CPF/CNPJ Denunciante',
    name: 'denunciante.cnpjCpf',
    type: 'STRING'
  },
  {
    label: 'Nome Denunciante',
    name: 'denunciante.nome',
    type: 'STRING'
  }
];

export const ProcessosListPage: React.FC<Props> = ({ history, location }) => {
  const [loadingPage, setLoadingPage] = useState(false);

  const rotinaProcessosComAtosComPrazoEncerrado = location.search.includes(
    'processosComAtosComPrazoEncerrado'
  );

  const find = useCallback(
    (
      search: string,
      pagination?: PageRequest | undefined,
      sort?: Sort | undefined
    ) => {
      if (rotinaProcessosComAtosComPrazoEncerrado) {
        return ProcessoService.processosComAtosComPrazoEncerrado(
          search,
          pagination
        );
      }
      return ProcessoService.load(search, pagination);
    },
    [rotinaProcessosComAtosComPrazoEncerrado]
  );

  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<Processo>({
    search: find,
    onError: useCallback(error => {
      Alert.error({ title: 'Erro ao buscar o processo.' }, error);
    }, [])
  });

  const viewProcesso = (processo: Processo) => {
    setLoadingPage(true);
    ProcessoService.validarPermissaoProcesso(processo.id)
      .then(response => {
        history.push(`/processo/${processo.id}/visualizar`);
      })
      .catch(error => {
        Alert.error({ title: 'Não foi possível visualizar o processo' }, error);
      })
      .finally(() => setLoadingPage(false));
  };

  function dataURLtoFile(dataurl: any, filename: any) {
    var bstr = atob(dataurl),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename);
  }

  const exportPlanilha = () => {
    setLoadingPage(true);
    ProcessoService.exportPlanilhaNotificacaoVencida()
      .then((response: any) => {
        if (response.status === 204) {
          Alert.info({
            title: 'Nenhum registro encontrado para o relatório.'
          });
        }
        const data = response.data;
        const filename = `Relatorio Notificação Vencida.xls`;

        download(dataURLtoFile(data, filename), filename);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório.` }, error);
      })
      .finally(() => setLoadingPage(false));
  };

  return (
    <>
      <Container breadcrumb>
        <Loading loading={loadingPage} />
        <Panel isTable>
          <SearchFilter
            fields={
              rotinaProcessosComAtosComPrazoEncerrado
                ? fieldsProcessosComAtosComPrazosEncerrados
                : fields
            }
            search={doSearch}
          />
          <Table<Processo>
            values={values}
            keyExtractor={item => item.id}
            loading={loading}
          >
            <Table.Column<Processo>
              header="Número Processo / Exercício"
              value={item => `${item.numero} / ${item.exercicio}`}
            />
            <Table.Column<Processo>
              header="Abertura"
              value={item => <FormattedDate value={item.dataAbertura} />}
            />
            <Table.Column<Processo>
              header="Fechamento"
              value={item => <FormattedDate value={item.dataFechamento} />}
            />
            <Table.Column<Processo>
              header="Protocolo"
              value={item => item.numeroProtocolo}
            />
            <Table.Column<Processo>
              header="Tempo"
              value={item => item.tempoFiscalizacao}
            />
            <Table.Column<Processo>
              header="Tipo do Processo"
              value={item => item.tipoProcesso.descricao}
            />
            <Table.Column<Processo>
              header="Razão Social"
              value={item => item.reu}
            />
            <Table.Column<Processo>
              header="Fiscal Responsável"
              value={item => item.fiscalResponsavelAtual?.pessoa?.nome}
            />
            <Table.Column<Processo>
              header="Situação"
              value={item => (
                <StatusRender
                  allSize={true}
                  idStatus={item.idStatus || ''}
                  status={item.descricaoStatus || ''}
                />
              )}
            />
            <Table.Column<Processo>
              data-test-id="buttons-col"
              header=""
              value={item => (
                <ActionsGroup>
                  <ActionButton
                    key={'view-button'}
                    icon="eye"
                    label="Visualizar"
                    onClick={() => viewProcesso(item)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={doPagedSearch}
            />
          )}
        </Panel>
        <div className="btn-save">
          <FabSpeedDial icon="ellipsis-v" title="Ações">
            <FAB
              icon="plus"
              iconColor="white"
              title="Adicionar Novo"
              path="/processo/novo"
            />
            <FAB
              icon="download"
              iconColor="white"
              title="Exportar Notificações Vencidas"
              onClick={exportPlanilha}
            />
          </FabSpeedDial>
        </div>
      </Container>
    </>
  );
};
